import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { truncate } from 'lodash';
import slugify from 'slugify';

import { Consumer } from 'store/createContext';

import {
  Container,
  PrevLink,
  NextLink,
  PrevTitle,
  NextTitle,
  Type,
  Date,
  Margin,
  StyledLink,
} from './project-header.css';

const ProjectHeader = ({ title, type, pageColor }) => (
  <Margin>
    <Container>
      <Consumer>
        {({ changeFilterProjects }) => (
          <div className="max-w-4xl w-full pt-32 xs:pt-48 mb-16 xs:mb-24">
            <span className="flex flex-col items-left pb-2">
              {type &&
                type.length > 0 &&
                type.map((t, i) => (
                  <span key={t}>
                    <Type>
                      <StyledLink
                        to={`/our-services#${slugify(t, { lower: true })}`}
                        onClick={() => {
                          changeFilterProjects(t);
                        }}
                      >
                        {t}
                      </StyledLink>
                    </Type>
                  </span>
                ))}
            </span>
            <h1
              className={`text-5p5xl text-brand-${pageColor} font-extralight mt-4 mb-10"`}
            >
              {title}
            </h1>
          </div>
        )}
      </Consumer>
    </Container>
  </Margin>
);

ProjectHeader.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  previous: PropTypes.object,
  next: PropTypes.object,
};

export default ProjectHeader;
